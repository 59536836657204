@font-face {
    font-family: 'Lexend';
    src: url('Lexend-Bold.eot');
    src: local('Lexend Bold'), local('Lexend-Bold'),
        url('Lexend-Bold.eot?#iefix') format('embedded-opentype'),
        url('Lexend-Bold.woff2') format('woff2'),
        url('Lexend-Bold.woff') format('woff'),
        url('Lexend-Bold.ttf') format('truetype'),
        url('Lexend-Bold.svg#Lexend-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend';
    src: url('Lexend-Medium.eot');
    src: local('Lexend Medium'), local('Lexend-Medium'),
        url('Lexend-Medium.eot?#iefix') format('embedded-opentype'),
        url('Lexend-Medium.woff2') format('woff2'),
        url('Lexend-Medium.woff') format('woff'),
        url('Lexend-Medium.ttf') format('truetype'),
        url('Lexend-Medium.svg#Lexend-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend';
    src: url('Lexend-Regular.eot');
    src: local('Lexend Regular'), local('Lexend-Regular'),
        url('Lexend-Regular.eot?#iefix') format('embedded-opentype'),
        url('Lexend-Regular.woff2') format('woff2'),
        url('Lexend-Regular.woff') format('woff'),
        url('Lexend-Regular.ttf') format('truetype'),
        url('Lexend-Regular.svg#Lexend-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend';
    src: url('Lexend-ExtraBold.eot');
    src: local('Lexend ExtraBold'), local('Lexend-ExtraBold'),
        url('Lexend-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Lexend-ExtraBold.woff2') format('woff2'),
        url('Lexend-ExtraBold.woff') format('woff'),
        url('Lexend-ExtraBold.ttf') format('truetype'),
        url('Lexend-ExtraBold.svg#Lexend-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend';
    src: url('Lexend-SemiBold.eot');
    src: local('Lexend SemiBold'), local('Lexend-SemiBold'),
        url('Lexend-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Lexend-SemiBold.woff2') format('woff2'),
        url('Lexend-SemiBold.woff') format('woff'),
        url('Lexend-SemiBold.ttf') format('truetype'),
        url('Lexend-SemiBold.svg#Lexend-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

