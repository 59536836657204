/* fonts  */
@import "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback";
/* Font Icons */
@import "./fontawesome-free/css/all.min.css";
/* bootstrap */
@import url('https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css');
/* Theme CSS */
@import "../../views/admin/dist/css/adminlte.min.css";

/* custom theme */

.content-section {
    position: relative;
    left: 250px;
    width: calc(100vw - 250px);
    height: calc(100vh - 57px);
    max-height: calc(100vh - 57px);
    overflow-y: auto;
    overflow-x: hidden;
}

.editorClass {
    min-height: 100px;
}